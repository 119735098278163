<template>
  <ul id="cardlist" :style="{ transform: `translate(${motionX}px, 0)` }">
    <li v-for="(card, index) in cards" :key="card.id">
      <Card
        :genre="card.genre"
        :label="card.label"
        :pList="card.pList"
        :color="card.color"
        :enable="isUIEnable"
        @click="selectCard(index)"
      />
    </li>
  </ul>
  <transition @enter="enter" @leave="leave" :css="false" appear>
    <div v-if="showApplyBtn" class="genre_btn">
      <Btn label="Выбрать" color="whiteGreyText" @click="applyGenre" />
    </div>
  </transition>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { GENRE_CARDS } from "@/js/_content.js";
import { useState } from "@/store";
import gsap from "gsap";
import Card from "@/components/Card";
import Btn from "@/components/Btn";
import Impetus from "impetus";

export default {
  components: {
    Card,
    Btn
  },
  setup() {
    const state = useState();
    const cards = ref(GENRE_CARDS);
    const isUIEnable = ref(false);
    const showApplyBtn = ref(false);
    const listWidth = ref(0);
    const motionX = ref(0);

    let impetus = null,
      valX = 0;

    const appearSceneInit = () => {
      const elems = document.querySelectorAll("#cardlist li");

      elems.forEach((elem, index) => {
        if (state.system.isDesktop) {
          gsap.from(elem, {
            y: index === 2 ? "-100vh" : 0,
            x:
              index === 0 || index === 1
                ? "-100vw"
                : index === 3 || index === 4
                ? "100vw"
                : 0,
            rotate:
              index === 2
                ? -100
                : index === 0
                ? 120
                : index === 1
                ? -140
                : index === 3
                ? 175
                : -95,
            ease: "power2.out",
            duration: 0.9,
            delay:
              index === 1 || index === 3
                ? 0.7
                : index === 0 || index === 4
                ? 1.1
                : 0.3
          });
        } else {
          gsap.from(elem, {
            y: "100vh",
            ease: "back.out(1.2)",
            duration: 0.6,
            delay: 0.5 + index * 0.3
          });
        }
      });

      setTimeout(() => {
        isUIEnable.value = true;
      }, 1500);
    };

    const selectCard = index => {
      if (!isUIEnable.value) return;

      const to = state.genre.selected === null ? true : false;

      if (impetus && to) impetus.pause();
      isUIEnable.value = false;
      showApplyBtn.value = to ? true : false;

      setTimeout(
        () => {
          state.genre.selected = to ? index : null;
          state.THREE.scene = to ? "genreSelected" : "genreSelectCancelled";
        },
        to ? 0 : 700
      );

      const elems = document.querySelectorAll("#cardlist li");

      elems.forEach((elem, i) => {
        if (i !== index) {
          gsap.to(elem, {
            y: to ? "100vh" : 0,
            delay: to ? i * 0.1 : 0.5 + i * 0.1,
            duration: 0.5,
            pointerEvents: to ? "none" : "all",
            ease: to ? "back.in(1.7)" : "back.out(1.7)"
          });
        } else {
          if (to) {
            const rect = elem.getBoundingClientRect(),
              nowToLeft = rect.left,
              nowToTop = rect.top,
              width = rect.width,
              height = rect.height,
              shouldBeToLeft = state.system.centerX - width / 2,
              shouldBeToTop = state.system.centerY - height / 2,
              neededToLeft = (shouldBeToLeft - nowToLeft).toFixed(1),
              neededToTop = (shouldBeToTop - nowToTop).toFixed(1);

            gsap.to(elem, {
              scale: state.system.isMobile ? 1.3 : 1.5,
              x: neededToLeft,
              y: neededToTop,
              duration: 1,
              ease: "back.out(1.7)"
            });
          } else {
            gsap.to(elem, {
              scale: 1,
              x: 0,
              y: 0,
              duration: 1,
              ease: "back.in(1.7)"
            });
          }

          const elemToFlip = elem.querySelector(".genre_card");

          gsap.to(elemToFlip, 2, {
            rotationY: "+=180",
            ease: "power2.inOut",
            yoyo: true,
            onComplete: () => {
              isUIEnable.value = true;
              if (impetus && !to) impetus.resume();
            }
          });

          gsap.to(elemToFlip, 1, {
            y: "-=50",
            z: "-=100",
            ease: "power2.in",
            yoyo: true,
            repeat: 1
          });
        }
      });
    };

    const enter = (el, done) => {
      gsap.from(el, {
        opacity: "0",
        y: 100,
        ease: "back.out(1.7)",
        duration: 0.5,
        delay: 1,
        onComplete: done
      });
    };

    const leave = (el, done) => {
      gsap.to(el, {
        opacity: "0",
        y: 100,
        ease: "back.in(1.7)",
        duration: 0.5,
        onComplete: done
      });
    };

    const applyGenre = () => {
      isUIEnable.value = false;
      showApplyBtn.value = false;

      const elem = document.querySelectorAll("#cardlist li")[
        state.genre.selected
      ];

      state.request.genre = state.genre.selected;
      setTimeout(() => {
        state.THREE.scene = "genreApplied";
      }, 500);

      gsap.to(elem, {
        y: "100vh",
        duration: 0.7,
        delay: 1.7,
        ease: "back.in(1.7)"
      });

      const elemToFlip = elem.querySelector(".genre_card");

      gsap.to(elemToFlip, 2, {
        rotationY: "+=180",
        ease: "power2.inOut",
        yoyo: true,
        onComplete: () => {
          isUIEnable.value = true;
        }
      });

      gsap.to(elemToFlip, 1, {
        y: "-=50",
        z: "-=100",
        ease: "power2.in",
        yoyo: true,
        repeat: 1
      });

      setTimeout(() => {
        state.router = {
          view: "Result",
          section: "FilmLoader"
        };
      }, 3000);
    };

    const animateScroll = () => {
      requestAnimationFrame(animateScroll);

      if (!isUIEnable.value || state.genre.selected) return;
      const x = 0.3 * (valX - motionX.value);
      motionX.value += x;
    };

    onMounted(() => {
      appearSceneInit();

      if (state.system.isAdaptive) {
        const area = document.querySelector("#genre_motionArea"),
          card = area.querySelector(".genre_card");

        listWidth.value = card.offsetWidth * 6 - state.system.width;

        impetus = new Impetus({
          source: area,
          multiplier: 1.5,
          boundX: [-listWidth.value, 0],
          update: x => {
            valX = x;
          }
        });

        animateScroll();
      }
    });

    onBeforeUnmount(() => {
      if (state.system.isAdaptive) {
        impetus.destroy();
      }
    });

    return {
      cards,
      state,
      selectCard,
      enter,
      leave,
      showApplyBtn,
      motionX,
      applyGenre,
      isUIEnable
    };
  }
};
</script>

<style lang="scss">
#cardlist {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
}

#cardlist li {
  margin: 0 1rem;
  position: relative;
}

.genre_btn {
  width: 17.6rem;
  height: 6.2rem;
  position: fixed;
  left: calc(50% - 8.8rem);
  bottom: 3vh;

  .btn {
    height: 100%;
    width: 100%;
  }
}

@include --tablet-v {
  #cardlist {
    justify-content: flex-start;
  }

  #cardlist li {
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
