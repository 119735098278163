<template>
  <div class="view home">
    <transition @enter="enter" @leave="leave" :css="false" mode="out-in" appear>
      <component :is="state.router.section" />
    </transition>
  </div>
</template>

<script>
import { useState } from "@/store";
import gsap from "gsap";
import Mood from "@/views/select/Mood";
import Genre from "@/views/select/Genre";

export default {
  components: {
    Mood,
    Genre
  },
  setup() {
    const state = useState();

    const enter = (el, done) => {
      gsap.from(el.querySelector("p"), {
        opacity: "0",
        y: 100,
        duration: 0.3,
        delay: 0.3,
        onComplete: done
      });

      gsap.from(el.querySelector(".h"), {
        x: "-100%",
        opacity: "0",
        duration: 1.5,
        ease: "elastic.out(1, 0.3)",
        delay: 0,
        onComplete: done
      });
    };

    const leave = (el, done) => {
      gsap.to(el, {
        opacity: "0",
        duration: 0.3,
        onComplete: done
      });
    };

    return { state, enter, leave };
  }
};
</script>
