<template>
  <div class="view home">
    <transition @enter="enter" @leave="leave" :css="false" mode="out-in" appear>
      <component :is="state.router.section" />
    </transition>
  </div>
</template>

<script>
import { useState } from "@/store";
import gsap from "gsap";
import Greeting from "@/views/home/Greeting";

export default {
  components: {
    Greeting
  },
  setup() {
    const state = useState();

    gsap.ticker.fps(60);

    const enter = (el, done) => {
      const delay = el.id == "greeting" ? 0.8 : 0;

      gsap.from(el.querySelector(".wrapper"), {
        opacity: "0",
        y: 100,
        duration: 0.3,
        delay: delay + 0.3,
        onComplete: done
      });

      gsap.from(el.querySelector(".h"), {
        x: "-100%",
        opacity: "0",
        duration: 1.5,
        ease: "elastic.out(1, 0.3)",
        delay: delay,
        onComplete: done
      });
    };

    const leave = (el, done) => {
      gsap.to(el, {
        opacity: "0",
        duration: 0.3,
        onComplete: done
      });
    };

    return { state, enter, leave };
  }
};
</script>
