<template>
  <section id="filmLoader">
    <div class="filmLoader_wp">
      <h2 class="h h2">
        <span>думаем и</span>
        <span>анализируем</span>
      </h2>
    </div>
  </section>
</template>

<script>
import { onMounted } from "vue";
import { useState } from "@/store";
import { getRandomInteger } from "../../js/utils/_other";

export default {
  setup() {
    const state = useState();

    const getFilm = arr => {
      let mood;
      let genre;

      switch (state.request.mood) {
        case 0:
          mood = "vanity";
          break;

        case 1:
          mood = "home";
          break;

        case 2:
          mood = "romantic";
          break;

        case 3:
          mood = "nostalgia";
          break;

        case 4:
          mood = "party";
          break;
      }

      switch (state.request.genre) {
        case 0:
          genre = "detective";
          break;

        case 1:
          genre = "adventure";
          break;

        case 2:
          genre = "thriller";
          break;

        case 3:
          genre = "comedy";
          break;

        case 4:
          genre = "melodrama";
          break;
      }

      const resultFilms = arr.filter(
        item => item.mood === mood && item.genre === genre
      );

      if (resultFilms.length > 1) {
        const rand = getRandomInteger(0, resultFilms.length - 1);

        state.response = resultFilms[rand];
      } else if (resultFilms.length) {
        state.response = resultFilms[0];
      } else {
        state.response = {};
      }

      setTimeout(() => {
        state.router.section = "FilmScreen";
      }, 5000);
    };

    const getJson = () => {
      fetch(state.system.url + "films.json")
        .then(res => res.json())
        .then(out => getFilm(out.films));
    };

    onMounted(() => {
      getJson();
      setTimeout(() => {
        state.THREE.scene = "filmLoadStarts";
      }, 200);
    });

    return { state };
  }
};
</script>

<style lang="scss" scoped>
#filmLoader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}
.filmLoader_wp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade 2.5s ease-in-out 1s infinite;

  .h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;

    span {
      display: inline-block;
      color: $color_white;
    }
  }
}

@keyframes fade {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
