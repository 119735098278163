<template>
  <section id="mood">
    <div class="section_header section_header-mood">
      <h2 class="h h2 whiteContent">
        Какое у вас новогоднее настроение?
      </h2>
      <p class="whiteContent">
        Вы в предвкушении шумной вечеринки или наоборот стремитесь провести
        вечер в тишине?
      </p>
    </div>
    <div class="motionArea" v-show="state.THREE.selectIsEnable">
      <mood-label type="cloud" :index="0" key="0" />
      <mood-label type="zzz" :index="1" key="1" />
      <mood-label type="sun" :index="2" key="2" />
      <mood-label type="star" :index="3" key="3" />
      <mood-label type="flower" :index="4" key="4" />
    </div>
    <transition @enter="enter" @leave="leave" :css="false" appear>
      <div v-if="btnIsVisible" class="mood_btn">
        <Btn label="Выбрать" :color="btnColor" @click="select" />
      </div>
    </transition>
  </section>
</template>

<script>
import { onMounted, computed } from "vue";
import { useState } from "@/store";
import gsap from "gsap";

import MoodLabel from "@/components/MoodLabel";
import Btn from "@/components/Btn";

export default {
  components: {
    MoodLabel,
    Btn
  },
  setup() {
    const state = useState();

    const btnIsVisible = computed(() => {
      return (
        state.system.isTouch &&
        state.THREE.selectedMood !== null &&
        state.request.mood === null
      );
    });

    const btnColor = computed(() => {
      const cond = state.THREE.selectedMood;
      if (cond === null) return;

      const result =
        cond === 0
          ? "navi"
          : cond === 1
          ? "white"
          : cond === 2
          ? "darkBlue"
          : cond === 3
          ? "yellowGreyText"
          : "orange";
      return result;
    });

    const select = () => {
      state.request.mood = state.THREE.selectedMood;

      setTimeout(() => {
        state.router.section = null;
        state.THREE.scene = "moodSelected";

        setTimeout(() => {
          state.router.section = "Genre";
        }, 1500);
      }, 200);
    };

    const enter = (el, done) => {
      gsap.from(el, {
        opacity: "0",
        y: 100,
        ease: "back.out(1.7)",
        duration: 0.5,
        onComplete: done
      });
    };

    const leave = (el, done) => {
      gsap.to(el, {
        opacity: "0",
        y: 100,
        ease: "back.in(1.7)",
        duration: 0.5,
        onComplete: done
      });
    };

    onMounted(() => {
      setTimeout(() => {
        state.THREE.scene = "mood";
        state.contentIsWhite = true;
      }, 400);
    });

    return { state, btnIsVisible, btnColor, select, enter, leave };
  }
};
</script>

<style lang="scss">
#mood {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.section_header-mood {
  display: flex;
  align-items: flex-end;

  p {
    margin: 0 0 0 10rem;
  }

  h2 {
    color: #ff390e;
  }
}

.motionArea {
  flex: 1;
  position: relative;
  width: 100vw;
  left: -10rem;
}

.mood_btn {
  width: 17.6rem;
  height: 6.2rem;
  position: fixed;
  left: calc(50% - 8.8rem);
  bottom: 3vh;

  .btn {
    height: 100%;
    width: 100%;
  }
}

@include --tablet {
  .section_header-mood {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin: 3rem 0 0 0;
    }
  }

  .motionArea {
    left: -4rem;
  }
}

@include --mobile-lg {
  .motionArea {
    left: -2rem;
  }
}
</style>
