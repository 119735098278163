<template>
  <canvas
    id="snowflakes"
    :style="{ zIndex: '1', pointerEvents: 'none' }"
  ></canvas>
</template>

<script>
import { onMounted } from "vue";
import gsap from "gsap";
import { useState } from "@/store";

export default {
  setup() {
    const state = useState();

    onMounted(() => {
      const canvas = document.getElementById("snowflakes");
      const ctx = canvas.getContext("2d");

      let canvasWidth;
      let canvasHeight;

      const updateSize = () => {
        canvasWidth = state.system.width;
        canvasHeight = state.system.height;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
      };

      updateSize();
      window.addEventListener("resize", updateSize, { passive: true });

      //Snowflake
      var flakes = state.system.isMobile ? 50 : 100; //max snowflakes
      var particles = [];
      for (var i = 0; i < flakes; i++) {
        particles.push({
          x: Math.random() * canvasWidth, // x-coordinate
          y: Math.random() * canvasHeight, // y-coordinate
          r: Math.random() * 4 + 1, // radius
          d: Math.random() * flakes // density
        });
      }

      //Draw the flakes
      const draw = () => {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
        ctx.beginPath();

        for (var i = 0; i < flakes; i++) {
          var p = particles[i];
          ctx.moveTo(p.x, p.y);
          ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
        }

        ctx.fill();
        update();
      };

      //Function for moving the snowflakes
      //Angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
      const update = () => {
        for (var i = 0; i < flakes; i++) {
          var p = particles[i];
          var angle = i / 100;

          //Updating X and Y coordinates
          //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
          //Every particle has its own density which can be used to make the downward movement different for each flake
          //make it more random by adding in the radius
          p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
          p.x += Math.sin(angle) * 2;

          //Sending flakes back from the top when it exits
          //Make it a bit more organic and let flakes enter from the left and right also.
          if (p.x > canvasWidth + 5 || p.x < -5 || p.y > canvasHeight) {
            if (i % 3 > 0) {
              //66% of the flakes
              particles[i] = {
                x: Math.random() * canvasWidth,
                y: -10,
                r: p.r,
                d: p.d
              };
            } else {
              //If the flake is exitting from the right
              if (Math.sin(angle) > 0) {
                //Enter from the left
                particles[i] = {
                  x: -5,
                  y: Math.random() * canvasHeight,
                  r: p.r,
                  d: p.d
                };
              } else {
                //Enter from the right
                particles[i] = {
                  x: canvasWidth + 5,
                  y: Math.random() * canvasHeight,
                  r: p.r,
                  d: p.d
                };
              }
            }
          }
        }
      };

      gsap.ticker.add(draw);
    });
  }
};
</script>
