<template>
  <div
    v-if="!state.system.isTouch"
    :class="[
      'moodLabel',
      {
        _selected: index === state.THREE.selectedMood,
        _clicked: state.request.mood !== null
      }
    ]"
    :style="getPosition"
    @mouseover="state.THREE.selectedMood = index"
    @mouseleave="state.THREE.selectedMood = null"
    @click="select"
  >
    <img :src="require(`../assets/moods/${type}.svg`)" alt="rounded text svg" />
  </div>

  <div
    v-else
    :class="[
      'moodLabel',
      {
        _selected: index === state.THREE.selectedMood,
        _clicked: state.request.mood !== null
      }
    ]"
    :style="getPosition"
    @click="state.THREE.selectedMood = index"
  >
    <img :src="require(`../assets/moods/${type}.svg`)" alt="rounded text svg" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useState } from "@/store";

export default {
  props: {
    type: String,
    index: Number
  },
  setup(props) {
    const state = useState();

    const getPosition = computed(() => {
      const el = state.THREE.moodsPos[props.index];
      if (!el) return;

      let width, height, top, left;

      if (state.system.width >= 1171) {
        width = 36;
      } else if (state.system.width >= 1024) {
        width = state.system.ratio < 1 ? 52 : 34;
      } else if (state.system.width >= 768) {
        width = 34;
      } else if (state.system.width < 768 && state.system.width >= 360) {
        width = 22;
      } else {
        width = 18;
      }

      height = width;
      const diviserTop = props.index === 4 ? 1.4 : 2;
      const diviserLeft = props.index === 4 ? 2.2 : 2;

      top = `calc(${el.y + "px"} - ${width / diviserTop}rem)`;
      left = `calc(${el.x + "px"} - ${width / diviserLeft}rem)`;

      return `width: ${width}rem; height: ${height}rem;  top: ${top}; left: ${left};`;
    });

    const select = () => {
      state.request.mood = state.THREE.selectedMood;

      setTimeout(() => {
        state.router.section = null;
        state.THREE.scene = "moodSelected";

        setTimeout(() => {
          state.router.section = "Genre";
        }, 2000);
      }, 200);
    };

    return { state, getPosition, select };
  }
};
</script>

<style lang="scss" scoped>
.moodLabel {
  position: fixed;
  cursor: pointer;
  animation: label-rotation 12s linear infinite;
  animation-play-state: paused;

  &._selected {
    animation-play-state: running;

    img {
      opacity: 1;
      transform: scale(1);
      transition: transform 0.5s $transition_backOut;
    }
  }

  &._clicked {
    img {
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.2s $transition_backIn;
    }
  }
}
img {
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(0.7);
  pointer-events: none;
  transition: transform 0.3s ease-in, opacity 0.15s ease-in;
}

@keyframes label-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
