<template>
  <button
    type="button"
    :aria-label="label"
    :class="[
      'btn',
      {
        mainColor: color == 'mainColor',
        yellow: color == 'yellow',
        darkBlue: color == 'darkBlue',
        orange: color == 'orange',
        yellowGreyText: color == 'yellowGreyText',
        white: color == 'white',
        whiteGreyText: color == 'whiteGreyText',
        navi: color == 'navi',
        hover: !state.system.isTouch
      }
    ]"
    @touchstart.passive="onClick"
  >
    <span>{{ label }}</span>
  </button>
</template>

<script>
import { useState } from "../store";

export default {
  props: {
    label: String,
    color: String
  },
  setup() {
    const state = useState();

    const onClick = e => {
      if (!state.system.isTouch) return;
      const target = e.target.closest("button");
      target.classList.add("_clicked");
      setTimeout(() => {
        target.classList.remove("_clicked");
      }, 300);
    };

    return { state, onClick };
  }
};
</script>

<style lang="scss" scoped>
.btn {
  height: 7.9rem;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  transition: all 0.3s ease;
  background-color: transparent;
}

.mainColor {
  background-color: #2461ff;
  color: #fff;
  overflow: hidden;
  position: relative;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    background: rgba($color: #ffffff, $alpha: 0.3);
  }

  &:after {
    left: -40%;
    top: 65%;
  }

  &:before {
    left: 30%;
    width: 15rem;
    top: 55%;
  }

  &.hover:hover,
  &._clicked {
    background-color: #4a7dfd;
  }
}

.yellow {
  background-color: $color_yellow;

  &.hover:hover,
  &._clicked {
    background-color: $color_yellow_darken;
  }
}

.darkBlue {
  background-color: $color_dark_blue;
  color: $color_white;

  &.hover:hover,
  &._clicked {
    background-color: $color_dark_blue_darken;
  }
}

.orange {
  background-color: $color_orange;
  color: $color_white;

  &.hover:hover,
  &._clicked {
    background-color: $color_orange_darken;
  }
}

.yellowGreyText {
  background-color: $color_yellow;
  color: $color_grey;

  &.hover:hover,
  &._clicked {
    background-color: $color_yellow_darken;
  }
}

.white {
  background-color: $color_white;
  color: $color_navi;

  &.hover:hover,
  &._clicked {
    background-color: $color_grey_light;
  }
}

.whiteGreyText {
  background-color: $color_white;
  color: $color_grey;

  &.hover:hover,
  &._clicked {
    background-color: $color_grey_light;
  }
}

.navi {
  background-color: $color_navi;
  color: $color_white;

  &.hover:hover,
  &._clicked {
    background-color: $color_navi_light;
  }
}

@include --mobile-lg {
  .btn {
    height: 6.2rem;
  }
}
</style>
