<template>
  <div class="view result">
    <transition @enter="enter" @leave="leave" :css="false" mode="out-in" appear>
      <component :is="state.router.section" />
    </transition>
  </div>
</template>

<script>
import { useState } from "@/store";
import gsap from "gsap";
import FilmLoader from "@/views/result/FilmLoader";
import FilmScreen from "@/views/result/FilmScreen";

export default {
  components: {
    FilmLoader,
    FilmScreen
  },
  setup() {
    const state = useState();

    const enter = (el, done) => {
      if (el.id === "filmLoader") {
        gsap.from(el, {
          opacity: "0",
          y: "100",
          duration: 0.5,
          delay: 0.7,
          onComplete: done
        });
      } else {
        const h2 = el.querySelector(".h2"),
          wrapper = el.querySelector(".wrapper"),
          filmCard = el.querySelector(".filmCard"),
          codeCard = el.querySelector(".codeCard"),
          adaptiveBtn = el.querySelector(".adaptive_return_btn"),
          checklist = el.querySelector(".checklist"),
          cards = checklist.querySelectorAll("img"),
          checkCloseBtn = checklist.querySelector(".checklist__closeBtn");

        if (state.system.width >= 768) {
          gsap.from(h2, {
            x: "-100%",
            opacity: "0",
            duration: 1.5,
            ease: "elastic.out(1, 0.3)",
            delay: 0.7,
            onComplete: done
          });
        }

        gsap.from(filmCard, {
          opacity: 0,
          x: 200,
          scale: 0,
          duration: 0.5,
          delay: 1.2,
          ease: "back.out(1.7)",
          onComplete: done
        });

        gsap.from(codeCard, {
          opacity: 0,
          x: -200,
          scale: 0,
          duration: 0.5,
          delay: 1.7,
          ease: "back.out(1.7)",
          onComplete: done
        });

        gsap.from(state.system.width >= 1024 ? wrapper : adaptiveBtn, {
          opacity: "0",
          y: 100,
          duration: 0.3,
          delay:
            state.system.width >= 1024
              ? 1
              : state.system.width >= 768
              ? 2.2
              : 2,
          onComplete: done
        });

        const checklistDelay = state.system.width >= 768 ? 2 : 2.5;

        gsap.from(checklist, {
          opacity: 0,
          y: 200,
          duration: 0.5,
          onComplete: done,
          delay: checklistDelay,
          ease: "back.out(1.7)"
        });

        gsap.from(cards[0], {
          y: 200,
          duration: 0.5,
          onComplete: done,
          delay: checklistDelay + 0.2
        });

        gsap.from(cards[1], {
          y: 200,
          duration: 0.5,
          onComplete: done,
          delay: checklistDelay + 0.3
        });

        if (state.system.width < 768) {
          gsap.from(checkCloseBtn, {
            opacity: 0,
            duration: 0.3,
            onComplete: done,
            delay: checklistDelay + 0.5
          });
        }
      }
    };

    const leave = (el, done) => {
      gsap.to(el, {
        opacity: "0",
        duration: 0.5,
        onComplete: done
      });
    };

    return { state, enter, leave };
  }
};
</script>
