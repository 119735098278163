<template>
  <section id="greeting">
    <h1 class="h h1">Какой фильм <span>посмотреть?</span></h1>
    <div class="wrapper">
      <p>
        Ответьте всего на два вопроса: какое у вас сейчас настроение и какой
        жанр кино вы предпочитаете? Мы предложим наиболее подходящий вам
        вариант!
      </p>
      <Btn @click="authDone" label="Начать" color="mainColor" />
    </div>
  </section>
</template>

<script>
import { onMounted } from "vue";
import { useState } from "@/store";
import Btn from "@/components/Btn";

export default {
  components: {
    Btn
  },
  setup() {
    const state = useState();

    const authDone = () => {
      state.THREE.scene = "authDone";
      state.router.section = null;

      setTimeout(() => {
        state.router = {
          view: "Select",
          section: "Mood"
        };
      }, 2100);
    };

    onMounted(() => {
      setTimeout(() => {
        state.THREE.scene = "greeting";
      }, 200);
    });

    return { state, authDone };
  }
};
</script>

<style lang="scss">
#greeting {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .h1 {
    margin-bottom: 11.4rem;
    max-width: 44rem;
  }

  p {
    max-width: 40rem;
  }

  button {
    width: 19.7rem;
    margin: 6.2rem 0;
  }
}

@include --tablet {
  #greeting {
    justify-content: start;

    .h1 {
      margin-top: 11.4rem;
    }
  }
}

@include --mobile-lg {
  #greeting {
    .h1 {
      margin: 4.8rem 0 2.4rem 0;
      max-width: 24.4rem;

      span {
        margin-top: 1rem;
      }
    }

    p {
      max-width: 28rem;
    }

    button {
      margin: 3.2rem 0;
    }
  }
}
</style>
