<template>
  <section id="genre">
    <div
      :class="[
        'section_header',
        'section_header-genre',
        { _hidden: state.genre.selected !== null }
      ]"
    >
      <h2 class="h h2">
        Какой жанр предпочитаете?
      </h2>
      <p>
        Чего вам хочется прямо сейчас — похохотать над комедией или окунуться в
        захватывающий сюжет боевика?
      </p>
    </div>
    <div id="genre_motionArea">
      <Cardlist />
    </div>
  </section>
</template>

<script>
import Cardlist from "@/components/Cardlist";
import { useState } from "@/store";

export default {
  components: {
    Cardlist
  },
  setup() {
    const state = useState();

    return { state };
  }
};
</script>

<style lang="scss">
#genre {
  height: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: $color_red;
  }
}

.section_header-genre {
  transition: opacity $transition_default;

  &._hidden {
    opacity: 0;
  }
}

#genre_motionArea {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include --tablet-v {
  #genre_motionArea {
    justify-content: flex-start;
    width: 100vw;
    position: relative;
    left: -4rem;
    padding: 0 4rem;
  }
}

@include --mobile-lg {
  #genre .h2 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}
</style>
