<template>
  <Home v-if="state.router.view == 'Home'" />
  <Select v-if="state.router.view == 'Select'" />
  <Result v-if="state.router.view == 'Result'" />

  <Snowflakes />
</template>

<script>
import { useState } from "@/store";
import { onMounted } from "vue";
import Home from "@/views/Home";
import Select from "@/views/Select";
import Result from "@/views/Result";
import Snowflakes from "@/components/Snowflakes.vue";

export default {
  components: {
    Home,
    Select,
    Result,
    Snowflakes
  },
  setup() {
    const state = useState();

    onMounted(() => (state.system.contentIsReady = true));
    return { state };
  }
};
</script>
