export const GENRE_CARDS = [
  {
    genre: "detective",
    label: "детектив",
    color: "#0b81d9",
    pList: [
      "Расследуйте загадочные происшествия вместе с героями фильма.",
      "Почувствуйте себя сыщиком: стройте собственные версии произошедшего, и пусть чутьё не подведёт вас!"
    ]
  },
  {
    genre: "adventures",
    label: "приключения",
    color: "#fef4c7",
    pList: [
      "Погрузитесь в атмосферу событий и наблюдайте, как наши герои выпутываются из сложных ситуаций.",
      "Счастливый конец весьма вероятен!"
    ]
  },
  {
    genre: "thriller",
    label: "боевик",
    color: "#992154",
    pList: [
      "Погони, каскадерские трюки и спецэффекты — всем этим изобилует жанр боевик.",
      "Если вы согласны, что «добро должно быть с кулаками», это ваш жанр!"
    ]
  },
  {
    genre: "comedy",
    label: "комедия",
    color: "#fcf25a",
    pList: [
      "Что может быть лучше, чем старая (современная) добрая комедия?",
      "Мы позаботились о том, чтобы подобрать фильмы, которые заставят вас хохотать!"
    ]
  },
  {
    genre: "melodrama",
    label: "мелодрама",
    color: "#fc9580",
    pList: [
      "Приготовьте свои носовые платочки — эти фильмы вызовут полную гамму эмоций!",
      "Здесь добро и зло, любовь и ненависть, трусость и отвага контрастируют друг с другом так ярко, как ни в одном другом жанре!"
    ]
  }
];
