<template>
  <div class="genre_card_flip_wp">
    <div class="genre_card" :style="{ cursor: enable ? 'pointer' : 'auto' }">
      <div class="card_content card_content-font">
        <div :style="{ backgroundColor: color }" class="font_img_wp">
          <img
            :src="getImg()"
            :alt="`${genre} jpg`"
            class="font_img font_img-top"
          />
        </div>
        <div class="card_label card_label-font">
          <span>{{ label }}</span>
        </div>
        <div :style="{ backgroundColor: color }" class="font_img_wp">
          <img
            :src="getImg()"
            :alt="`${genre} jpg`"
            class="font_img font_img-bottom"
          />
        </div>
      </div>
      <div class="card_content card_content-back">
        <div class="card-back_header">
          <img :src="getImg()" :alt="`${genre} jpg`" class="back_img" />
          <div class="card_label card_label-back">
            <span>{{ label }}</span>
          </div>
        </div>
        <p
          v-for="(p, index) in pList"
          :key="p.id"
          :style="{
            marginTop: state.system.isMobile && index === 0 ? '0' : '1rem'
          }"
        >
          {{ p }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useState } from "@/store";

export default {
  props: {
    genre: String,
    label: String,
    pList: Array,
    color: String,
    enable: Boolean
  },
  setup(props) {
    const state = useState();

    const getImg = () => {
      if (state.system.pixelRatio < 2) {
        return require(`../assets/cards/${props.genre}.jpg`);
      } else {
        return require(`../assets/cards/${props.genre}@2x.jpg`);
      }
    };

    return { state, getImg };
  }
};
</script>

<style lang="scss" scoped>
.genre_card_flip_wp {
  transform-style: preserve-3d;
  perspective: 900px;
  perspective-origin: 50% 50% 0px;
}

.genre_card {
  position: relative;
  transform-style: preserve-3d;
  width: 24.3rem;
  height: 39rem;
  border-radius: 1rem;
  background: $color_white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 34px 54px rgba(0, 0, 0, 0.25);
}

.card_content {
  overflow: hidden;
  position: absolute;
  width: calc(100% - 1.6rem);
  height: calc(100% - 1.6rem);
  backface-visibility: hidden;
  background: $color_white;

  img {
    pointer-events: none;
  }
}

.card_content-back {
  transform: rotateY(-180deg);
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: $color_white;
  border-radius: 1rem;
}

.font_img_wp {
  height: calc(50% - 1.5rem);
  width: 100%;
  overflow: hidden;
}

.font_img {
  height: auto;
  width: 100%;

  &-bottom {
    transform: rotate(180deg);
  }
}

.card_label {
  width: 100%;
  background: $color_white;
  text-transform: uppercase;
  // font-family: $cardFont;
  font-weight: 900;
  letter-spacing: 0.7rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;

  span {
    position: relative;
    left: 4px;
  }

  &-back {
    position: absolute;
    bottom: 0;
    height: 4rem;
  }
}

.card-back_header {
  position: relative;
}

.back_img {
  width: 100%;
  height: auto;
}

p {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

@include --mobile-lg {
  .genre_card {
    width: 20rem;
    height: 34rem;
  }

  .card_label {
    font-size: 1.2rem;
    letter-spacing: 0.5rem;

    span {
      left: 2.5px;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

@include --mobile-sm {
  .genre_card {
    width: 17rem;
    height: 28rem;
  }

  .card_label {
    font-size: 1.1rem;
    letter-spacing: 0.4rem;

    &-back {
      height: 3rem;
    }

    span {
      left: 2px;
    }
  }

  p {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
}
</style>
