<template>
  <section id="filmScreen">
    <div class="section_header section_header-filmScreen">
      <h2
        class="h h2"
        :style="{
          color:
            state.response.headerColor && state.system.width >= 1024
              ? state.response.headerColor
              : state.system.width >= 1024
              ? '#18454A'
              : '#050505'
        }"
      >
        вам подойдет
      </h2>
      <div class="wrapper">
        <p>
          На основании ваших предпочтений мы осмелимся предположить, что именно
          от этого шедевра кинематографа вы получите массу положительных эмоций.
          Устраивайтесь поудобнее!
        </p>
        <Btn label="пройти ещё раз" color="mainColor" @click="reset" />
      </div>
    </div>
    <div class="filmArea">
      <div class="filmArea_wp">
        <div class="filmCard">
          <div class="filmCard__header">
            <div class="filmCard__img">
              <img :src="getImg()" :alt="state.response.film + ' cover jpg'" />
            </div>
            <div class="filmCard__info" v-if="state.system.width < 768">
              <h3 class="h h3">
                {{ state.response.film ? state.response.film : "Нет данных" }}
              </h3>
              <ul>
                <li>
                  Страна:
                  {{ state.response.country ? state.response.country : "Н/Д" }}
                </li>
                <li>
                  Жанр:
                  {{ state.response.genre ? state.response.genreLabel : "Н/Д" }}
                </li>
                <li>
                  Год:
                  {{ state.response.year ? state.response.year : "Н/Д" }}
                </li>
              </ul>
            </div>
          </div>

          <div class="filmCard__content">
            <h3 class="h h3" v-if="state.system.width >= 768">
              {{ state.response.film ? state.response.film : "Нет данных" }}
            </h3>
            <p>
              {{
                state.response.description
                  ? state.response.description
                  : "Нет данных"
              }}
            </p>
          </div>
          <img
            class="cardCaret cardCaret-film"
            src="../../assets/card_caret.svg"
            alt="message caret svg"
          />
        </div>
        <div v-if="!state.system.shareApiIsSupported" class="codeCard">
          <div class="codeCard__header codeCard__header-noShareApi">
            Поделиться
          </div>
          <ul class="share">
            <li v-for="item in shareButtons" :key="item.id">
              <a
                :href="item.href"
                :title="item.label"
                target="_blank"
                rel="noopener"
                :arial-label="item.label"
                ><img
                  :src="require(`../../assets/${item.icon}`)"
                  :alt="item.label"
              /></a>
            </li>
            <li>
              <a
                href="/"
                title="Копировать"
                aria-label="Копировать"
                @click.prevent="copyCode"
                ><img
                  src="../../assets/copy.svg"
                  alt="copy icon"
                  aria-label="Copy link"
              /></a>
            </li>
          </ul>
          <img
            class="cardCaret cardCaret-code"
            src="../../assets/card_caret_blue.svg"
            alt="message caret svg"
          />
        </div>
        <div v-else @click="openShareBar" class="codeCard codeCard-shareApi">
          <div class="codeCard__icon">
            <img src="../../assets/repost.svg" alt="repost icon" />
          </div>
          <div class="codeCard__header">Поделиться</div>
          <svg
            class="codeCard__caret"
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.656854 1.00001L6.31371 6.65686L0.656854 12.3137"
              stroke="#ffffff"
            />
          </svg>
          <img
            class="cardCaret cardCaret-code"
            src="../../assets/card_caret_blue.svg"
            alt="message caret svg"
          />
        </div>
        <a
          class="checklist"
          href="https://45.paraweb.me/"
          target="_blank"
          rel="noopener"
        >
          <div
            v-if="state.system.width < 768"
            @click.prevent="closeChecklist"
            class="checklist__closeBtn"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times"
              class="svg-inline--fa fa-times fa-w-11"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352 512"
            >
              <path
                fill="black"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </div>
          <div class="checklist__img">
            <img src="../../assets/45card.jpg" alt="45 tattoo card" />
            <img src="../../assets/45card2.jpg" alt="45 tattoo card" />
          </div>
          <div class="checklist__content">
            <h3 class="h h3">А еще специально предложение для тебя!</h3>
            <p>Дарим чек-лист проверки сайта</p>
            <div class="checklist__link">
              <span>Перейти</span>
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.656854 1.00001L6.31371 6.65686L0.656854 12.3137"
                  stroke="#000000"
                />
              </svg>
            </div>
          </div>
          <img
            v-if="state.system.width >= 768"
            class="cardCaret cardCaret-checklist"
            src="../../assets/card_caret.svg"
            alt="message caret svg"
          />
        </a>
      </div>
      <div class="adaptive_return_btn">
        <Btn label="пройти ещё раз" color="mainColor" @click="reset" />
      </div>
    </div>

    <div :class="['copy_done', { _hidden: !copyIsActive }]">
      Скопировано
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from "vue";
import { useState } from "@/store";
import Btn from "@/components/Btn";
import gsap from "gsap";

export default {
  components: {
    Btn
  },
  setup() {
    const state = useState();
    const copyIsActive = ref(false);

    const shareButtons = [
      {
        label: "Поделиться на Facebook",
        icon: "fb.svg",
        href: `https://facebook.com/sharer/sharer.php?u=${state.system.url}`
      },
      {
        label: "Поделиться во Vkontakte",
        icon: "vk.svg",
        href: `https://vk.com/share.php?url=${state.system.url}`
      },
      {
        label: "Поделиться в Telegram",
        icon: "telegram.svg",
        href: `https://t.me/share/url?url=${state.system.url}`
      }
    ];

    const copyCode = () => {
      navigator.clipboard.writeText(state.system.url).then(() => {
        copyIsActive.value = true;
        setTimeout(() => {
          copyIsActive.value = false;
        }, 2000);
      });
    };

    const openShareBar = () => {
      navigator.share({
        title: document.title,
        url: state.system.url
      });
    };

    const getImg = () => {
      if (state.system.pixelRatio < 2) {
        if (state.response.cover) {
          return state.response.cover;
        } else return "/films/common.jpg";
      } else {
        if (state.response.cover2x) {
          return state.response.cover2x;
        } else return "/films/common@2x.jpg";
      }
    };

    const closeChecklist = e => {
      gsap.set(e.target.closest(".checklist__closeBtn"), {
        opacity: 0
      });

      gsap.to(e.target.closest(".checklist"), {
        opacity: 0,
        y: 200,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.15
      });
    };

    const reset = () => {
      state.THREE.selectedMood = null;
      state.genre.selected = null;
      state.request = {
        mood: null,
        genre: null
      };

      state.THREE.scene = "reset";
      state.router.section = null;

      setTimeout(() => {
        state.router = {
          view: "Select",
          section: "Mood"
        };
      }, 2100);
    };

    onMounted(() => {
      setTimeout(() => {
        state.THREE.scene = "filmLoadEnds";
      }, 200);
    });

    return {
      state,
      shareButtons,
      openShareBar,
      getImg,
      copyCode,
      copyIsActive,
      closeChecklist,
      reset
    };
  }
};
</script>

<style lang="scss">
#filmScreen {
  display: flex;
  justify-content: space-between;

  p {
    margin: 4rem 0;
  }

  button {
    width: 25.4rem;
  }
}

.adaptive_return_btn {
  display: none;
}

.filmArea {
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 7.3rem;
  flex-direction: column;
}

.filmCard,
.checklist {
  display: flex;
  background-color: $color_white;
  border-radius: 2rem;
  position: relative;
  width: 60rem;
}

.filmCard {
  align-items: center;
  padding: 1rem 2.4rem 1rem 1rem;

  .h3 {
    text-transform: uppercase;
    color: #0c0c0c;
    margin-bottom: 2.3rem;
  }
}

.filmCard__img {
  width: 19.3rem;
  height: 29rem;
  overflow: hidden;
  border-radius: 2rem;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.filmCard__content {
  margin-left: 3.4rem;
  p {
    margin: 0 !important;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.cardCaret {
  position: absolute;
}

.cardCaret {
  width: 3rem;
  height: 3.9rem;
  bottom: -1rem;
}

.cardCaret-film,
.cardCaret-checklist {
  right: -0.5rem;
}

.codeCard {
  width: fit-content;
  margin-top: 2rem;
  border-radius: 2rem;
  padding: 2.4rem 2.8rem;
  color: $color_white;
  background: $color_blue_code;
  position: relative;
  display: flex;
  align-items: center;
}

.codeCard-shareApi {
  padding: 1.6rem 1.8rem;
  cursor: pointer;

  &:hover {
    .codeCard__caret {
      transform: translateX(3px);
    }
  }
}

.codeCard__header {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  font-style: italic;
  line-height: 2.1rem;
  text-decoration: underline;
  width: fit-content;
  position: relative;
  z-index: 1;
}

.cardCaret-code {
  left: -0.5rem;
}

.share {
  margin-left: 2rem;
  display: flex;

  li:not(:last-child) {
    margin-right: 1.2rem;
  }
}

.codeCard a,
.codeCard__icon {
  display: block;
  width: 30px;
  height: 30px;
  background: rgba(240, 245, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: rgba(240, 245, 255, 1);
  }
}

.codeCard__icon {
  pointer-events: none;
  margin-right: 1.5rem;
}

.codeCard__caret,
.checklist__link svg {
  position: relative;
  top: 0.5px;
  width: 1rem;
  margin-left: 1rem;
  transition: transform $transition_default;
}

.copy_done {
  font-weight: 700;
  color: #fff;
  padding: 1.4rem 1.8rem;
  border-radius: 2rem;
  background: #4bb543;
  position: fixed;
  z-index: 5;
  bottom: 5vh;
  right: 5vh;
  pointer-events: none;
  transition: all 0.3s $transition_backOut;

  &._hidden {
    visibility: hidden;
    transition: all 0.3s $transition_backIn;
    transform: translateY(calc(6rem + 5vh));
  }
}

.checklist {
  justify-content: space-between;
  margin-top: 3rem;
  position: relative;

  &:hover {
    .checklist__link svg {
      transform: translateX(3px);
    }
  }
}

.checklist__closeBtn {
  background: $color_white;
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  right: 0rem;
  top: -3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 2rem;
    width: 2rem;
  }
}

.checklist__img {
  position: relative;
  flex-shrink: 0;
  height: 15rem;
  width: 11.6rem;
  overflow: hidden;
  margin: 2rem 0 0 2rem;

  img:first-child {
    width: 8.8rem;
    height: 13rem;
    position: relative;
    z-index: 1;
  }

  img:last-child {
    position: absolute;
    width: 10rem;
    height: 15rem;
    left: 1.6rem;
    bottom: -5rem;
  }
}

.checklist__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin: 2rem;

  h3 {
    color: $color_black;
    max-width: 40rem;
  }

  p {
    margin: 2rem 0 !important;
  }
}

.checklist__link {
  font-style: italic;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
  position: relative;
  z-index: 1;
}

@include --tablet {
  #filmScreen {
    flex-direction: column;
    justify-content: start;

    .h2 {
      display: none;
    }

    button {
      margin-top: 4rem;
    }
  }

  .section_header-filmScreen .wrapper {
    display: none;
  }

  .adaptive_return_btn {
    display: block;
  }

  .filmArea {
    flex: 0;
    align-items: flex-start;
    margin-top: 0;
  }

  .filmCard {
    padding: 2.4rem;

    .h3 {
      margin-bottom: 1.2rem;
    }
  }

  .filmCard__img {
    width: 12rem;
    height: 14.1rem;

    img {
      height: auto;
    }
  }
}

@include --mobile-lg {
  #filmScreen {
    button {
      width: auto;
    }
  }

  .adaptive_return_btn {
    align-self: center;
  }

  .filmCard {
    padding: 1.6rem;
    flex-direction: column;
    align-items: start;
    width: calc(100vw - 4rem);
  }

  .filmCard h3 {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  .filmCard__header {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  .filmCard__info {
    margin-left: 1.6rem;
  }

  .filmCard__info ul {
    li {
      font-weight: 700;
      font-size: 1.4rem;

      &:not(:last-child) {
        margin-bottom: 0.6rem;
      }
    }
  }

  .filmCard__content {
    margin-left: 0;

    p {
      line-height: 1.4rem;
      font-size: 1.2rem;
    }
  }

  .checklist {
    position: fixed;
    z-index: 3;
    width: calc(100% - 2rem);
    left: 1rem;
    bottom: 1rem;
    margin-top: 0;
    align-items: center;

    h3 {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    p {
      margin: 1.5rem 0 !important;
    }
  }

  .checklist__content {
    margin: 1.8rem;
  }

  .checklist__img {
    margin: 1.8rem 0 0 1.8rem;
  }
}

@include --mobile-sm {
  .codeCard__header-noShareApi {
    display: none;
  }

  .share {
    margin-left: 0;
  }
}

@media (max-width: 767px) and (max-height: 550px) {
  .filmCard__header {
    margin-bottom: 0;
  }

  .filmCard__content p {
    display: none;
  }
}
</style>
